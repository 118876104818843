






























































































































import Vue from "vue";

import updateUserData from "@/api/requests/updateUserData";

import TCard from "@/components/core/TCard.vue";
import TCategoryDivider from "@/components/core/TCategoryDivider.vue";
import Btn from "@/components/core/TBtn.vue";
import Cep from "@/components/core/TCepInput.vue";
import Cpf from "@/components/core/TCpfInput.vue";
import Password from "@/components/core/TPasswordInput.vue";
import PhoneInput from "@/components/core/TPhoneInput.vue";
import DatePicker from "@/components/core/TDatePicker.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TSelect from "@/components/core/TSelect.vue";
import User from "@/types/User";
import getAvaibleGenders from "@/api/requests/getAvaibleGenders";
import GenderData from "@/types/GenderData";

type Form = Vue & { validate: () => boolean };

export default Vue.extend({
  name: "UserData",

  components: {
    Btn,
    Cep,
    Cpf,
    Password,
    PhoneInput,
    TCard,
    TCategoryDivider,
    DatePicker,
    TSelect,
    TTextInput,
  },

  mounted() {
    this.setNameRules();
    this.getGenders();
  },

  beforeMount() {
    this.userData.id = this.user.id;
    this.userData.name = this.user.name;
    this.userData.document = this.user.document;
    this.userData.email = this.user.email;
    this.userData.birth_date = this.user.birth_date;
    this.userData.cellphone = this.user.cellphone;
    this.userData.gender_id = this.user.gender_id;
    if (this.user.address) {
      this.userData.address = this.user.address;
    }
  },

  data: () => ({
    userData: {
      id: "",
      name: "",
      document: "",
      email: "",
      birth_date: "",
      cellphone: "",
      address: {
        zipcode: "",
        street: "",
        state: "",
        city: "",
        neighborhood: "",
        number: "",
        complement: "",
      },
      gender_id: 0,
    } as User,
    genders: [] as Array<GenderData>,
    password: {
      value: "",
      confirm: "",
    },
    nameRules: [] as Array<(v?: string) => boolean | string>,
    loading: false,
  }),

  computed: {
    user(): User {
      return this.$store.state.user.data;
    },
    userDataForm(): Form {
      return this.$refs.userDataForm as Form;
    },
  },

  methods: {
    async getGenders() {
      const genders = await getAvaibleGenders();
      this.genders = genders.data;
    },
    setNameRules() {
      this.nameRules = [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (v?: string): boolean | string =>
          !this.userData.name || this.userData.name.length > 2 || "O nome precisa ter mais que 2 letras.",
      ] as Array<(v?: string) => boolean | string>;
    },
    handleCepFetch({
      zipcode,
      neighborhood,
      street,
      city,
      state,
    }: {
      zipcode: string;
      neighborhood: string;
      street: string;
      city: string;
      state: string;
    }) {
      this.userData.address.zipcode = zipcode;
      this.userData.address.neighborhood = neighborhood;
      this.userData.address.street = street;
      this.userData.address.city = city;
      this.userData.address.state = state;
    },

    async handleUserUpdate(): Promise<void> {
      if (!this.userDataForm.validate()) {
        this.$snackbar("Verifique os dados inseridos!", "error");
        return;
      }

      this.loading = true;

      try {
        const user = (await updateUserData(this.userData)).data;

        this.$store.commit("user/setUser", user);

        this.$snackbar("Seus dados foram atualizados com sucesso!", "success");
      } catch (error) {
        this.$snackbar(error, "error");
      } finally {
        this.loading = false;
      }
    },

    async handlePasswordUpdate(): Promise<void> {
      if (!this.userDataForm.validate()) {
        this.$snackbar("Verifique os dados inseridos!", "error");
        return;
      }

      if (this.password.value !== this.password.confirm) {
        this.$snackbar("As senhas não coincidem!", "error");
        return;
      }

      if (this.password.value.trim() === "") {
        this.$snackbar("Você precisa definir uma nova senha para atualizá-la!", "error");
        return;
      }

      this.loading = true;

      try {
        const user = (await updateUserData({ ...this.user, password: this.password.value })).data;

        this.$store.commit("user/setUser", user);

        this.$snackbar("Sua senha foi atualizada com sucesso!", "success");
      } catch (error) {
        this.$snackbar(error, "error");
      } finally {
        this.loading = false;
      }
    },
  },
});
