<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="input-date-picker"
        v-model="dateFormatted"
        :label="label"
        v-bind="attrs"
        @blur="dateRaw = parseDate(dateFormatted)"
        v-on="on"
        outlined
        v-mask="'##/##/####'"
        :dense="dense"
        hide-details=""
        :disabled="loading"
        :append-icon="icon"
        :rules="rules"
        :readonly="readonly"
      ></v-text-field>
    </template>
    <v-date-picker
      locale="pt-BR"
      v-model="dateRaw"
      no-title
      :type="type"
      @input="menu = false"
      :readonly="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dateRaw: this.value,
      dateFormatted: this.formatDate(this.value),
      menu: false,
    };
  },
  props: {
    value: {},
    loading: {
      required: true,
      type: Boolean,
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "DatePicker",
    },
    type: {
      type: String,
      default: "date",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateRaw);
    },
  },
  watch: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    dateRaw(val) {
      this.$emit("input", this.dateRaw);
      this.dateFormatted = this.formatDate(this.dateRaw);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return "";

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  created() {
    if (this.value) {
      this.dateRaw = this.value;
      this.dateFormatted = this.formatDate(this.value);
    }
  },
});
</script>
